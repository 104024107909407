.rtl-enabled {
    direction: rtl;

    // Card
    .trezo-card {
        .trezo-card-header {
            .trezo-card-title {
                .search-box {
                    label {
                        left: auto;
                        right: 13px;
                    }
                    .input-search {
                        padding: {
                            left: 16px;
                            right: 38px;
                        };
                    }
                }
            }
            .trezo-card-subtitle {
                .dot-menu {
                    margin: {
                        left: -2px;
                        right: 0;
                    };
                }
                .menu-btn {
                    span {
                        padding: {
                            left: 20px;
                            right: 0;
                        };
                        &::before {
                            right: auto;
                            left: -3px;
                        }
                    }
                    &.without-border {
                        span {
                            padding: {
                                right: 0;
                                left: 18px;
                            };
                        }
                    }
                }
                .dropdown-menu {
                    right: auto;
                    left: 0;
    
                    li {
                        .dropdown-item {
                            i {
                                left: auto;
                                right: 20px;
                            }
                            &::before {
                                left: auto;
                                right: 0;
                            }
                            &.with-icon {
                                padding: {
                                    left: 20px;
                                    right: 47px;
                                };
                            }
                        }
                    }
                }
                .search-box {
                    label {
                        left: auto;
                        right: 13px;
                    }
                    .input-search {
                        padding: {
                            right: 38px;
                            left: 15px;
                        };
                    }
                }
                .add-btn {
                    span {
                        padding: {
                            left: 0;
                            right: 22px;
                        };
                        i {
                            left: auto;
                            right: -4px;
                        }
                    }
                }
                .buttons-list {
                    .button {
                        margin: {
                            right: 0;
                            left: 15px;
                        };
                    }
                    .trezo-card-header-menu {
                        .button {
                            margin-left: 0;
                        }
                    }
                }
                .date-btn {
                    margin: {
                        right: 0;
                        left: 10px;
                    };
                    span {
                        padding: {
                            left: 0;
                            right: 26px;
                        };
                        i {
                            left: auto;
                            right: 0;
                        }
                    }
                }
                .export-csv-btn {
                    span {
                        padding: {
                            left: 0;
                            right: 26px;
                        };
                        i {
                            left: auto;
                            right: 0;
                        }
                    }
                }
            }
        }
        .trezo-card-content {
            .form-btn-box {
                button {
                    margin: {
                        right: 0;
                        left: 20px;
                    };
                    &.primary {
                        span {
                            padding: {
                                left: 0;
                                right: 29px;
                            };
                            i {
                                left: auto;
                                right: 0;
                            }
                        }                    
                    }
                    &:last-child {
                        margin-left: 0;
                    }
                }
            }
            .trezo-card-pagination {
                ul, ol {
                    li {
                        .prev, .next {
                            i {
                                transform: translateY(-50%) scaleX(-1);
                            }
                        }
                        &:first-child {
                            margin: {
                                left: 3px;
                                right: 0;
                            };
                        }
                        &:last-child {
                            margin: {
                                right: 3px;
                                left: 0;
                            };
                        }
                    }
                }
            }
        }
        &.table-style-two {
            .trezo-card-content {
                .trezo-card-pagination {
                    div {
                        ol, ul {
                            margin: {
                                left: 0;
                                right: 10px;
                            };
                        }
                    }
                }
            }
        }
    }

    // ApexCharts
    .apexcharts-canvas {
        direction: ltr;

        .apexcharts-tooltip {
            direction: rtl;

            .apexcharts-tooltip-marker {
                margin: {
                    right: 0;
                    left: 6px;
                };
            }
            .apexcharts-tooltip-series-group {
                justify-content: start;
            }
        }
        .apexcharts-legend-series {
            direction: rtl;

            .apexcharts-legend-marker {
                left: auto !important;
                right: -2px;
                margin: {
                    right: 0;
                    left: 3px;
                };
            }
            .apexcharts-legend-text {
                padding: {
                    left: 0;
                    right: 15px;
                };
                margin: {
                    left: 0;
                    right: -15px;
                };
            }
        }
    }

    // Table
    .basic-table {
        table, .table {
            thead {
                tr {
                    th {
                        &:first-child {
                            border: {
                                top: {
                                    right-radius: 0;
                                    left-radius: 7px;
                                };
                            };
                        }
                        &:last-child {
                            border: {
                                top: {
                                    left-radius: 0;
                                    right-radius: 7px;
                                };
                            };
                        }
                    }
                }
            }
            tbody {
                tr {
                    td {
                        .product-info {
                            .info {
                                margin: {
                                    left: 0;
                                    right: 12px;
                                };
                            }
                        }
                        .customer-info {
                            span {
                                margin: {
                                    left: 0;
                                    right: 12px;
                                };
                            }
                        }
                        .user-info {
                            .image {
                                margin: {
                                    right: 0;
                                    left: 12px;
                                };
                            }
                        }
                        .action-buttons {
                            button, a {
                                margin: {
                                    right: 0;
                                    left: 9px;
                                };
                                &:last-child {
                                    margin-left: 0;
                                }
                            }
                        }
                        .assignees {
                            div {
                                margin: {
                                    right: 0;
                                    left: -13px;
                                };
                                &:last-child {
                                    margin-left: 0;
                                }
                            }
                        }
                        .ratings {
                            i {
                                margin: {
                                    right: 0;
                                    left: 2px;
                                };
                                &:last-child {
                                    margin-left: 0;
                                }
                            }
                        }
                        .group-lesson {
                            .users {
                                div {
                                    margin: {
                                        right: 0;
                                        left: -21px;
                                    };
                                    &:last-child {
                                        margin-left: 0;
                                    }
                                }
                            }
                            .info {
                                margin: {
                                    left: 0;
                                    right: 10px;
                                };
                            }
                        }
                        .circle-progress-bar {
                            svg {
                                left: auto;
                                right: -5px;
                            }
                        }
                        .file-name {
                            i {
                                margin: {
                                    right: 0;
                                    left: 8px;
                                };
                            }
                        }
                        .review {
                            .ratings {
                                i {
                                    margin: {
                                        right: 0;
                                        left: 2px;
                                    };
                                    &:last-child {
                                        margin-left: 0;
                                    }
                                }
                            }
                        }
                        .speaker-info {
                            span {
                                margin: {
                                    left: 0;
                                    right: 12px;
                                };
                            }
                        }
                        .impressions {
                            padding: {
                                left: 0;
                                right: 28px;
                            };
                            i {
                                left: auto;
                                right: 0;
                            }
                        }
                        .clicks {
                            padding: {
                                left: 0;
                                right: 28px;
                            };
                            i {
                                left: auto;
                                right: 0;
                            }
                        }
                        &:first-child {
                            border: {
                                right-width: 0;
                                left: 1px solid var(--borderColor);
                            };
                        }
                        &:last-child {
                            border: {
                                left-width: 0;
                                right: 1px solid var(--borderColor);
                            };
                        }
                    }
                }
            }
        }
    }

    // Paginator
    .pagination-card {
        ul, ol {
            li {
                a {
                    i {
                        transform: translateY(-50%) scaleX(-1);
                    }
                }
                &:first-child {
                    margin: {
                        left: 3px;
                        right: 0;
                    };
                }
                &:last-child {
                    margin: {
                        right: 3px;
                        left: 0;
                    };
                }
            }
        }
    }
    .pagination-list {
        .page-item {
            &:first-child {
                .page-link {
                    border: {
                        top-left-radius: 0;
                        bottom-left-radius: 0;
                        top-right-radius: var(--pagination-border-radius);
                        bottom-right-radius: var(--pagination-border-radius);
                    };
                }
            }
            &:last-child {
                .page-link {
                    border: {
                        top-right-radius: 0;
                        bottom-right-radius: 0;
                        top-left-radius: var(--pagination-border-radius);
                        bottom-left-radius: var(--pagination-border-radius);
                    };
                }
            }
        }
    }

    // Checkbox
    .form-check {
        padding: {
            left: 0;
            right: 1.5em;
        };
        .form-check-input {
            float: right;
            margin: {
                left: 0;
                right: -1.5em;
            };
        }
        &.form-switch {
            padding: {
                left: 0;
                right: 2.5em;
            };
            .form-check-input {
                background-position: right center;
                margin: {
                    right: -2.5em;
                    left: 0;
                };
            }
        }
    }

    // Owl Carousel
    .owl-carousel {
        direction: ltr;
    }

    // Form Select
    .form-select {
        background-position: left 0.75rem center;
    }

    // Add New Popup
    .add-new-popup {
        .popup-dialog {
            .trezo-card {
                .trezo-card-content {
                    .btn-box {
                        button {
                            margin: {
                                right: 0;
                                left: 15px;
                            };
                            &.primary {
                                span {
                                    padding: {
                                        left: 0;
                                        right: 25px;
                                    };
                                    i {
                                        left: auto;
                                        right: 0;
                                    }
                                }                    
                            }
                            &:last-child {
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    // Breadcrumb
    .breadcrumb-card {
        .breadcrumb {
            .breadcrumb-item {
                a {
                    padding: {
                        left: 0;
                        right: 22px;
                    };
                    i {
                        left: auto;
                        right: 0;
                    }
                }
                &:before {
                    left: -22px;
                    right: auto;
                    content: "\ea64";
                }
                &:first-child {
                    margin: {
                        left: 13px;
                        right: 0;
                    };
                }
                &:last-child {
                    margin: {
                        right: 13px;
                        left: 0;
                    };
                }
            }
        }
    }

    // File Uploader
    .file-uploader {
        .upload-input {
            .icon {
                float: right;
            }
            .upload-text {
                padding: {
                    left: 0;
                    right: 12px;
                };
            }
        }
    }

    // NGX Editor
    .NgxEditor__Wrapper {
        .NgxEditor__MenuBar {
            .NgxEditor__Dropdown {
                .NgxEditor__Dropdown--Text {
                    &::after {
                        margin: {
                            left: 0;
                            right: 5px;
                        };
                    }
                }
            }
        }
    }

    // Accordion
    .accordion-button {
        &::after {
            margin: {
                left: 0;
                right: auto;
            };
        }
    }

    // Button Group
    .btn-group {
        &.me-2 {
            margin: {
                left: 0.5rem !important;
                right: 0 !important;
            };
        }
    }
    .btn-group > .btn:nth-child(n+3), .btn-group > :not(.btn-check) + .btn, .btn-group > .btn-group:not(:first-child) > .btn {
        border: {
            top-right-radius: 0 !important;
            bottom-right-radius: 0 !important;
            top-left-radius: var(--bs-btn-border-radius);
            bottom-left-radius: var(--bs-btn-border-radius);
        };
    }
    .btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn.dropdown-toggle-split:first-child, .btn-group > .btn-group:not(:last-child) > .btn {
        border: {
            top-left-radius: 0;
            bottom-left-radius: 0;
            top-right-radius: var(--bs-btn-border-radius);
            bottom-right-radius: var(--bs-btn-border-radius);
        };
    }
    .btn-group > :not(.btn-check:first-child) + .btn, .btn-group > .btn-group:not(:first-child) {
        margin: {
            right: calc(var(--bs-border-width)* -1);
            left: 0;
        };
    }

    // Breadcrumb
    .breadcrumb-item + .breadcrumb-item {
        padding: {
            left: 0;
            right: var(--bs-breadcrumb-item-padding-x);
        };
    }
    .breadcrumb-item + .breadcrumb-item {
        &::before {
            float: right;
            padding: {
                right: 0;
                left: var(--bs-breadcrumb-item-padding-x);
            };
        }
    }

    // List Group
    .list-group {
        padding-right: 0;

        .ms-2 {
            margin: {
                left: 0 !important;
                right: 0.5rem !important;
            };
        }
        .me-auto {
            margin: {
                left: auto !important;
                right: 0 !important;
            };
        }
        .me-2 {
            margin: {
                right: 0 !important;
                left: 0.5rem !important;
            };
        }
    }
    .list-group-horizontal > .list-group-item:first-child:not(:last-child) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: var(--bs-list-group-border-radius);
        border-top-right-radius: var(--bs-list-group-border-radius);
        border-top-left-radius: 0;
    }
    .list-group-horizontal > .list-group-item:last-child:not(:first-child) {
        border-top-right-radius: 0;
        border-top-left-radius: var(--bs-list-group-border-radius);
        border-bottom-right-radius: 0;
        border-bottom-left-radius: var(--bs-list-group-border-radius);
    }
    .list-group-horizontal > .list-group-item + .list-group-item {
        border-right-width: 0;
        border-left-width: var(--bs-list-group-border-width);
    }

    // Modal
    .modal-header {
        .btn-close {
            margin: calc(-0.5* var(--bs-modal-header-padding-y)) auto calc(-0.5* var(--bs-modal-header-padding-y)) calc(-0.5* var(--bs-modal-header-padding-x));
        }
    }

    // Navs & Tabs
    .nav {
        padding-right: 0;
    }

    // Offcanvas
    .offcanvas-header {
        .btn-close {
            margin: {
                right: auto;
                left: calc(-0.5 * var(--bs-offcanvas-padding-x));
            };
        }
    }

    // Pagination
    .pagination {
        padding-right: 0;
    }
    .page-item {
        &:first-child {
            .page-link {
                border: {
                    top-left-radius: 0;
                    bottom-left-radius: 0;
                    top-right-radius: var(--bs-pagination-border-radius);
                    bottom-right-radius: var(--bs-pagination-border-radius);
                };
            }
        }
        &:last-child {
            .page-link {
                border: {
                    top-right-radius: 0;
                    bottom-right-radius: 0;
                    top-left-radius: var(--bs-pagination-border-radius);
                    bottom-left-radius: var(--bs-pagination-border-radius);
                };
            }
        }
    }

    // Toasts
    .toast {
        .me-auto {
            margin: {
                left: auto !important;
                right: 0 !important;
            };
        }
    }
    .toast-header {
        .btn-close {
            margin: {
                left: calc(-0.5* var(--bs-toast-padding-x));
                right: var(--bs-toast-padding-x);
            };
        }
    }

    // Typography
    dd {
        margin-right: 0;
    }

    // Input Group
    .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control, .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
        border: {
            top-right-radius: var(--bs-border-radius);
            bottom-right-radius: var(--bs-border-radius);
            top-left-radius: 0 !important;
            bottom-left-radius: 0 !important;
        };
    }
    .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
        border: {
            top-right-radius: 0;
            bottom-right-radius: 0;
            top-left-radius: var(--bs-border-radius);
            bottom-left-radius: var(--bs-border-radius);
        };
        margin: {
            left: 0;
            right: calc(var(--bs-border-width) * -1);
        };
    }

    // Floating Labels
    .form-floating {
        > label {
            left: auto;
            right: 0;
        }
    }
    .form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-control-plaintext ~ label, .form-floating > .form-select ~ label {
        transform: scale(0.85) translateY(-0.5rem) translateX(1rem);
    }
    .input-group > .form-floating:not(:first-child) > .form-control, .input-group > .form-floating:not(:first-child) > .form-select {
        border: {
            top-left-radius: var(--bs-border-radius);
            bottom-left-radius: var(--bs-border-radius);
            top-right-radius: 0;
            bottom-right-radius: 0;
        };
    }

    // Margin
    .mr-15 {
        margin: {
            right: 0 !important;
            left: 15px !important;
        };
    }
    .ml-15 {
        margin: {
            right: 15px !important;
            left: 0 !important;
        };
    }
    .mr-20 {
        margin: {
            right: 0 !important;
            left: 20px !important;
        };
    }
    .ml-20 {
        margin: {
            right: 20px !important;
            left: 0 !important;
        };
    }
    .mr-10 {
        margin: {
            right: 0 !important;
            left: 10px !important;
        };
    }
    .ml-10 {
        margin: {
            right: 10px !important;
            left: 0 !important;
        };
    }
    .mr-17 {
        margin: {
            right: 0 !important;
            left: 17px !important;
        };
    }
    .ml-17 {
        margin: {
            right: 17px !important;
            left: 0 !important;
        };
    }
    .mr-12 {
        margin: {
            right: 0 !important;
            left: 12px !important;
        };
    }
    .ml-12 {
        margin: {
            right: 12px !important;
            left: 0 !important;
        };
    }

}

/* Max width 767px */
@media only screen and (max-width : 767px) {

    .rtl-enabled {

        // Card
        .trezo-card {
            .trezo-card-header {
                .trezo-card-title {
                    .search-box {
                        label {
                            left: auto;
                            right: 10px;
                        }
                        .input-search {
                            padding: {
                                left: 15px;
                                right: 35px;
                            };
                        }
                    }
                }
                .trezo-card-subtitle {
                    .menu-btn {
                        span {
                            padding: {
                                right: 0;
                                left: 17px;
                            };
                            &::before {
                                right: auto;
                                left: -2px;
                            }
                        }
                        &.without-border {
                            span {
                                padding: {
                                    left: 17px;
                                    right: 0;
                                };
                                &::before {
                                    right: auto;
                                    left: -2px;
                                }
                            }
                        }
                    }
                    .dropdown-menu {
                        li {
                            .dropdown-item {
                                i {
                                    left: auto;
                                    right: 20px;
                                }
                                &.with-icon {
                                    padding: {
                                        left: 20px;
                                        right: 47px;
                                    };
                                }
                            }
                        }
                    }
                    .search-box {
                        label {
                            left: auto;
                            right: 10px;
                        }
                        .input-search {
                            padding: {
                                left: 15px;
                                right: 35px;
                            };
                        }
                    }
                }
            }
            .trezo-card-content {
                .trezo-card-pagination {
                    ul, ol {
                        li {
                            &:first-child {
                                margin: {
                                    left: 2px;
                                };
                            }
                            &:last-child {
                                margin: {
                                    right: 2px;
                                };
                            }
                        }
                    }
                }
                .form-btn-box {
                    button {
                        margin: {
                            right: 0;
                            left: 15px;
                        };
                        &.primary {
                            span {
                                padding: {
                                    left: 0;
                                    right: 25px;
                                };
                            }
                        }
                    }
                }
            }
            &.table-style-two {
                .basic-table {
                    .table, table {
                        thead {
                            tr {
                                td {
                                    &:first-child {
                                        padding: {
                                            left: 17px;
                                            right: 17px;
                                        };
                                    }
                                    &:last-child {
                                        padding: {
                                            right: 17px;
                                            left: 17px;
                                        };
                                    }
                                }
                            }
                        }
                        tbody {
                            tr {
                                td {
                                    &:first-child {
                                        padding: {
                                            left: 17px;
                                            right: 17px;
                                        };
                                    }
                                    &:last-child {
                                        padding: {
                                            right: 17px;
                                            left: 17px;
                                        };
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        // Table
        .basic-table {
            table, .table {
                tbody {
                    tr {
                        td {
                            .product-info {
                                .info {
                                    margin: {
                                        left: 0;
                                        right: 10px;
                                    };
                                }
                            }
                            .customer-info {
                                span {
                                    margin: {
                                        left: 0;
                                        right: 10px;
                                    };
                                }
                            }
                            .user-info {
                                .image {
                                    margin: {
                                        right: 0;
                                        left: 10px;
                                    };
                                }
                            }
                            .speaker-info {
                                span {
                                    margin: {
                                        left: 0;
                                        right: 10px;
                                    };
                                }
                            }
                        }
                    }
                }
            }
        }

    }

}

/* Min width 576px */
@media (min-width: 576px) {

    .rtl-enabled {
        
        // List Group
        .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: var(--bs-list-group-border-radius);
            border-top-right-radius: var(--bs-list-group-border-radius);
            border-top-left-radius: 0;
        }
        .list-group-horizontal-sm > .list-group-item + .list-group-item {
            border-left-width: var(--bs-list-group-border-width);
            border-right-width: 0;
        }
        .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
            border-top-right-radius: 0;
            border-top-left-radius: var(--bs-list-group-border-radius);
            border-bottom-left-radius: var(--bs-list-group-border-radius);
            border-bottom-right-radius: 0;
        }

    }

}

/* Min width 576px to Max width 767px */
@media only screen and (min-width : 576px) and (max-width : 767px) {

    .rtl-enabled {

        // Card
        .trezo-card {
            &.table-style-two {
                .basic-table {
                    .table, table {
                        thead {
                            tr {
                                td {
                                    &:first-child {
                                        padding: {
                                            left: 20px;
                                            right: 20px;
                                        };
                                    }
                                    &:last-child {
                                        padding: {
                                            right: 20px;
                                            left: 20px;
                                        };
                                    }
                                }
                            }
                        }
                        tbody {
                            tr {
                                td {
                                    &:first-child {
                                        padding: {
                                            left: 20px;
                                            right: 20px;
                                        };
                                    }
                                    &:last-child {
                                        padding: {
                                            right: 20px;
                                            left: 20px;
                                        };
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

    }

}

/* Min width 768px */
@media (min-width: 768px) {

    .rtl-enabled {
        
        // List Group
        .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: var(--bs-list-group-border-radius);
            border-top-right-radius: var(--bs-list-group-border-radius);
            border-top-left-radius: 0;
        }
        .list-group-horizontal-md > .list-group-item + .list-group-item {
            border-left-width: var(--bs-list-group-border-width);
            border-right-width: 0;
        }
        .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
            border-top-right-radius: 0;
            border-top-left-radius: var(--bs-list-group-border-radius);
            border-bottom-left-radius: var(--bs-list-group-border-radius);
            border-bottom-right-radius: 0;
        }

    }

}

/* Min width 992px */
@media (min-width: 992px) {

    .rtl-enabled {
        
        // List Group
        .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: var(--bs-list-group-border-radius);
            border-top-right-radius: var(--bs-list-group-border-radius);
            border-top-left-radius: 0;
        }
        .list-group-horizontal-lg > .list-group-item + .list-group-item {
            border-left-width: var(--bs-list-group-border-width);
            border-right-width: 0;
        }
        .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
            border-top-right-radius: 0;
            border-top-left-radius: var(--bs-list-group-border-radius);
            border-bottom-left-radius: var(--bs-list-group-border-radius);
            border-bottom-right-radius: 0;
        }

    }

}

/* Min width 1200px */
@media (min-width: 1200px) {

    .rtl-enabled {
        
        // List Group
        .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: var(--bs-list-group-border-radius);
            border-top-right-radius: var(--bs-list-group-border-radius);
            border-top-left-radius: 0;
        }
        .list-group-horizontal-xl > .list-group-item + .list-group-item {
            border-left-width: var(--bs-list-group-border-width);
            border-right-width: 0;
        }
        .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
            border-top-right-radius: 0;
            border-top-left-radius: var(--bs-list-group-border-radius);
            border-bottom-left-radius: var(--bs-list-group-border-radius);
            border-bottom-right-radius: 0;
        }

    }

}

/* Min width 1400px */
@media (min-width: 1400px) {

    .rtl-enabled {
        
        // List Group
        .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: var(--bs-list-group-border-radius);
            border-top-right-radius: var(--bs-list-group-border-radius);
            border-top-left-radius: 0;
        }
        .list-group-horizontal-xxl > .list-group-item + .list-group-item {
            border-left-width: var(--bs-list-group-border-width);
            border-right-width: 0;
        }
        .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
            border-top-right-radius: 0;
            border-top-left-radius: var(--bs-list-group-border-radius);
            border-bottom-left-radius: var(--bs-list-group-border-radius);
            border-bottom-right-radius: 0;
        }

    }

}